<template>
    <default-template>
        <!--Main Section [S]-->
        <div class="main-contents">

            <slide-banner v-if="!$vuetify.breakpoint.mobile" class="main-pc" code="main-pc" />
            <slide-banner v-else class="main-mo" code="main-mo" />

            <!--기존 구조 [S]-->
            <v-responsive width="100%" min-height="100%" class="overflow-visible">
                <v-responsive width="100%" max-width="1230" class="main-section main-section--product  mx-auto" style="overflow:visible;">

                    <main-giftcard>
                        <span slot="header" class="font-weight-bold main-category--title">모바일 상품권</span>
                    </main-giftcard>
                    
                </v-responsive>
            </v-responsive>
            <!--기존 구조 [E]-->


            <!--Section Baner [S]-->
            <div class="main-section main-section--banner">
                <div class="section-banner--wrap">
                    <!--pc-->
                    <img class="event-pc" src="images/main/main-banner.png" alt="secion-banner">
                    <!--mo-->
                    <img class="event-mo" src="images/main/main-banner-mo.png" alt="secion-banner">
                </div>
            </div>
            <!--Section Baner [E]-->

            <!--Section Board [S]-->
            <div class="main-section main-section--board">
                <div class="section-board-wrap" style="max-width:1230px; width:100%; padding:0px 15px; margin:0 auto;">
                    <v-row justify="center">
                        <!-- <v-col cols="12" md="6">
                            <main-question-list />
                        </v-col> -->
                        <v-col cols="12" class="mt-1 mt-md-0">
                            <main-faq-list />
                            <main-notification-list class="mt-7" />
                        </v-col>
                    </v-row>
                </div>
            </div>
            <!--Section Board [E]-->
        </div>
        <!--Main Section [E]-->
    </default-template>
</template>
<script>
import DefaultTemplate from "../DefaultTemplate.vue";
import GiftcardView from "@/components/client/gshop/giftcard/giftcard-view.vue";
import SlideBanner from "@/components/client/banner/slide-banner.vue";
import MainCategoryList from "@/components/client/main/main-category-list.vue";
import MainCategoryItem from "@/components/client/main/main-category-item.vue";
import MainGiftcard from "@/components/client/main/main-giftcard-list.vue";
import MainQuestionList from "@/components/client/main/main-question-list.vue";
import MainFaqList from "@/components/client/main/main-faq-list.vue";
import MainNotificationList from "@/components/client/main/main-notification-list.vue";

export default {
    metaInfo() {
        let metaInfo = { 
        meta: [
            { name: "google-site-verification", content: "8C0of3HDrdPt4keGcl0DBJjvBAlxy3TvfTn8Qbq_3-c" },
            { name: "naver-site-verification", content: "bf90d0be55e8e40182b577e96eb3219df6ef378f" },
        ],
        };

        Object.assignDefined(metaInfo, this.$store.state.metatags.metaInfo);

        return metaInfo;
    },
    components: {
        DefaultTemplate,
        SlideBanner,
        GiftcardView,
        MainCategoryList,
        MainCategoryItem,
        MainGiftcard,
        MainQuestionList,
        MainFaqList,
        MainNotificationList,
    }
}
</script>